<template>
  <ListAssetTypesStyled>
    <PanelTableMolecule
      :isLoading="loading"
      :count="totalCount"
      :title="$tc('assetType', 2) | capitalize"
      :columns="columns"
      :rows="tableData"
      :pageIndex="page + 1"
      :pageSize="pageSize"
      :createRouteName="'createAssetType'"
      @pageNumberChange="pageNumberChange($event)"
      @reload="reload"
    />
    <router-view @reload="reload" />
  </ListAssetTypesStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import get from 'lodash/get'

import PanelTableMolecule from '@/components/Atomic/Molecules/PanelTableMolecule'
import ASSETS_TYPES_QUERY from '#/graphql/assetTypes/assetTypes.gql'

const ListAssetTypesStyled = styled('div')`
  margin: 1rem;
`

export default {
  components: {
    ListAssetTypesStyled,
    PanelTableMolecule,
  },
  data() {
    return {
      loading: true,
      totalCount: 0,
      models: [],
      errors: [],
      searchQuery: '',
      where: null,
      pageSize: 10,
      page: 0,
    }
  },
  computed: {
    columns() {
      const header = [
        { field: 'assetTypeId', key: 'assetTypeId', title: 'ID', align: 'left' },
        { field: 'name', key: 'name', title: 'name', align: 'center' },
        { field: 'symbol', key: 'symbol', title: 'symbol', align: 'center' },
      ]
      return header
    },
    tableData() {
      const tableData = this.models.map(assetTypes => {
        return {
          assetTypeId: assetTypes.id,
          name: assetTypes.name,
          symbol: assetTypes.symbol,
          rowKey: assetTypes.id,
        }
      })
      return tableData
    },
    first() {
      return this.page * this.pageSize
    },
    last() {
      return this.first + (this.pageSize - 1)
    },
  },
  methods: {
    reload() {
      this.$apollo.queries.models.refetch()
      this.$apollo.queries.assetTypes.refetch()
    },
    pageNumberChange(pageIndex) {
      this.page = pageIndex - 1
    },
  },
  apollo: {
    models: {
      query: ASSETS_TYPES_QUERY,
      variables() {
        return {
          cursor: {
            first: this.first,
            last: this.last,
          },
        }
      },
      manual: true,
      result({ data, loading }) {
        this.loading = loading
        this.models = get(data, 'assetTypes', [])
        this.count = this.models.length
      },
    },
    assetTypes: {
      query: ASSETS_TYPES_QUERY,
      update({ assetTypes }) {
        this.totalCount = assetTypes?.length
      },
    },
  },
}
</script>
